import React, { Component } from 'react';
import Image from './Image'
export default class Experience extends Component {
    constructor(props) {
        super(props);
        // typo: use `=` instead of `:`
        let width = window.innerWidth;
        // dont use setState in constructor, initialize state instead
        this.state = {};
        if (width > 768) {
            // set renderComponent property according to window size
            // components are declared using JSX, not string (do not use ``)
            this.state.renderComponent = (
                <React.Fragment>

                    <section id="resume">
                        <div className="row">
                            <div className='three columns'>
                                <h2><span style={{borderBottom: '2px solid', borderBottomColor:'#f22613'}}>Experience</span></h2>
                            </div>
                            <div className="nine columns main-col">
                                <h4>Ultimate Software</h4>
                                <h5>Software Engineer II</h5>
                                <h6>January 2021 - Current | San Francisco</h6>
                                <p>
                                    • Building the next generation workforce performance development product using <b>Angular</b>, <b>Java</b>, <b>RabbitMQ</b>, and <b>Mongo</b>
                                </p>

                                <h5>Software Engineer I</h5>
                                <h6>January 2020 - December 2020 | San Francisco</h6>
                                <p>
                                    • Developed a <b>Java</b> microservice that handles user login, SSO, and session management from ground up using <b>Spring</b> and <b>Redis</b> <br/>
                                    • Improved the application security by implementing data encryption and other measures to protect the application from common attacks such as session hijacking and CSRF <br/>
                                    • Increased scaling and deployment velocity by migrating the application from a <b>Python Flask</b> monolith into a event-driven <b>Java Spring</b> microservice architecture while ensuring the security and integrity of beta customers’ data
                                </p>

                                <h5>Software Engineer Intern</h5>
                                <h6>July 2019 - December 2019 | San Diego</h6>
                                <p>
                                    • Saved engineering hours by automating CCPA / GDPR data privacy requests by designing and shipping a microservice from scratch using <b>Java</b> and <b>Kafka</b>
                                </p>
                                <hr/>

                                <h4>San Diego Supercomputer Center, UCSD</h4>
                                <h5>Software Developer / Researcher </h5>
                                <h6>January 2018 - December 2019, Part-time</h6>
                                <p>
                                    • Designed and built a platform for large-scale heterogeneous social media data research using <b>Java</b>, <b>Python</b>, <b>Bootstrap</b>, <b>PostgreSQL</b>, and <b>Neo4j</b>. The platform provides insights into social media data through graph-based exploration and data aggregation <br/>
                                    • Co-authored and published paper with IEEE <a href="https://ieeexplore.ieee.org/document/9041791" target="_blank">"Multi-model Investigative Exploration of Social Media Data with BOUTIQUE: A Case Study in Public Health."</a> Presented the paper at 2019 15th International Conference on eScience. <br/>
                                    • Implemented a pipeline that identifies user interaction network clusters and categorizes their behaviors by sampling and analyzing unstructured social media data using word2vec and topic model analysis <br/>
                                </p>
                                <hr/>
                                <h4>iOS Developer</h4>
                                <h6>April 2014 - January 2016</h6>
                                <p>
                                    • Developed and published iOS apps using <b>Objective-C</b> that generated 40,400 total downloads
                                </p>



                            </div> {/* end .main-col */}

                        </div>
                    </section> {/* About Section End*/}
                    <div className="separator red">
                        <div>
                            <svg id preserveAspectRatio="xMidYMax meet" className="svg-separator sep1" viewBox="0 0 1600 100" style={{}} data-height={100}>
                                <path className style={{opacity: 1, fill: '#34495e'}} d="M1040,56c0.5,0,1,0,1.6,0c-16.6-8.9-36.4-15.7-66.4-15.7c-56,0-76.8,23.7-106.9,41C881.1,89.3,895.6,96,920,96
C979.5,96,980,56,1040,56z" />
                                <path className style={{opacity: 1, fill: '#34495e'}} d="M1699.8,96l0,10H1946l-0.3-6.9c0,0,0,0-88,0s-88.6-58.8-176.5-58.8c-51.4,0-73,20.1-99.6,36.8
c14.5,9.6,29.6,18.9,58.4,18.9C1699.8,96,1699.8,96,1699.8,96z" />
                                <path className style={{opacity: 1, fill: '#34495e'}} d="M1400,96c19.5,0,32.7-4.3,43.7-10c-35.2-17.3-54.1-45.7-115.5-45.7c-32.3,0-52.8,7.9-70.2,17.8
c6.4-1.3,13.6-2.1,22-2.1C1340.1,56,1340.3,96,1400,96z" />
                                <path className style={{opacity: 1, fill: '#34495e'}} d="M320,56c6.6,0,12.4,0.5,17.7,1.3c-17-9.6-37.3-17-68.5-17c-60.4,0-79.5,27.8-114,45.2
c11.2,6,24.6,10.5,44.8,10.5C260,96,259.9,56,320,56z" />
                                <path className style={{opacity: 1, fill: '#34495e'}} d="M680,96c23.7,0,38.1-6.3,50.5-13.9C699.6,64.8,679,40.3,622.2,40.3c-30,0-49.8,6.8-66.3,15.8
c1.3,0,2.7-0.1,4.1-0.1C619.7,56,620.2,96,680,96z" />
                                <path className style={{opacity: 1, fill: '#34495e'}} d="M-40,95.6c28.3,0,43.3-8.7,57.4-18C-9.6,60.8-31,40.2-83.2,40.2c-14.3,0-26.3,1.6-36.8,4.2V106h60V96L-40,95.6
z" />
                                <path className style={{opacity: 1, fill: '#2c3e50'}} d="M504,73.4c-2.6-0.8-5.7-1.4-9.6-1.4c-19.4,0-19.6,13-39,13c-19.4,0-19.5-13-39-13c-14,0-18,6.7-26.3,10.4
C402.4,89.9,416.7,96,440,96C472.5,96,487.5,84.2,504,73.4z" />
                                <path className style={{opacity: 1, fill: '#2c3e50'}} d="M1205.4,85c-0.2,0-0.4,0-0.6,0c-19.5,0-19.5-13-39-13s-19.4,12.9-39,12.9c0,0-5.9,0-12.3,0.1
c11.4,6.3,24.9,11,45.5,11C1180.6,96,1194.1,91.2,1205.4,85z" />
                                <path className style={{opacity: 1, fill: '#2c3e50'}} d="M1447.4,83.9c-2.4,0.7-5.2,1.1-8.6,1.1c-19.3,0-19.6-13-39-13s-19.6,13-39,13c-3,0-5.5-0.3-7.7-0.8
c11.6,6.6,25.4,11.8,46.9,11.8C1421.8,96,1435.7,90.7,1447.4,83.9z" />
                                <path className style={{opacity: 1, fill: '#2c3e50'}} d="M985.8,72c-17.6,0.8-18.3,13-37,13c-19.4,0-19.5-13-39-13c-18.2,0-19.6,11.4-35.5,12.8
c11.4,6.3,25,11.2,45.7,11.2C953.7,96,968.5,83.2,985.8,72z" />
                                <path className style={{opacity: 1, fill: '#2c3e50'}} d="M743.8,73.5c-10.3,3.4-13.6,11.5-29,11.5c-19.4,0-19.5-13-39-13s-19.5,13-39,13c-0.9,0-1.7,0-2.5-0.1
c11.4,6.3,25,11.1,45.7,11.1C712.4,96,727.3,84.2,743.8,73.5z" />
                                <path className style={{opacity: 1, fill: '#2c3e50'}} d="M265.5,72.3c-1.5-0.2-3.2-0.3-5.1-0.3c-19.4,0-19.6,13-39,13c-19.4,0-19.6-13-39-13
c-15.9,0-18.9,8.7-30.1,11.9C164.1,90.6,178,96,200,96C233.7,96,248.4,83.4,265.5,72.3z" />
                                <path className style={{opacity: 1, fill: '#2c3e50'}} d="M1692.3,96V85c0,0,0,0-19.5,0s-19.6-13-39-13s-19.6,13-39,13c-0.1,0-0.2,0-0.4,0c11.4,6.2,24.9,11,45.6,11
C1669.9,96,1684.8,96,1692.3,96z" />
                                <path className style={{opacity: 1, fill: '#2c3e50'}} d="M25.5,72C6,72,6.1,84.9-13.5,84.9L-20,85v8.9C0.7,90.1,12.6,80.6,25.9,72C25.8,72,25.7,72,25.5,72z" />
                                <path className style={{fill: '#606c88'}} d="M-40,95.6C20.3,95.6,20.1,56,80,56s60,40,120,40s59.9-40,120-40s60.3,40,120,40s60.3-40,120-40
s60.2,40,120,40s60.1-40,120-40s60.5,40,120,40s60-40,120-40s60.4,40,120,40s59.9-40,120-40s60.3,40,120,40s60.2-40,120-40
s60.2,40,120,40s59.8,0,59.8,0l0.2,143H-60V96L-40,95.6z" />
                            </svg>
                        </div>
                    </div>

                </React.Fragment>
            );
        } else {
            this.state.renderComponent = (
                <React.Fragment>

                    <section id="resume">
                        <div className="row">
                            <div className='three columns'>
                                <h2><span style={{borderBottom: '2px solid', borderBottomColor:'#f22613'}}>Experience</span></h2>
                            </div>
                            <div className="nine columns main-col">
                                <h4>Ultimate Software</h4>
                                <h5>Software Engineer II</h5>
                                <h6>January 2021 - Current | San Francisco</h6>
                                <p>
                                    • Building the next generation workforce performance development product using <b>Angular</b>, <b>Java</b>, <b>RabbitMQ</b>, and <b>Mongo</b>
                                </p>

                                <h5>Software Engineer I</h5>
                                <h6>January 2020 - December 2020 | San Francisco</h6>
                                <p>
                                    • Developed a <b>Java</b> microservice that handles user login, SSO, and session management from ground up using <b>Spring</b> and <b>Redis</b> <br/>
                                    • Improved the application security by implementing data encryption and other measures to protect the application from common attacks such as session hijacking and CSRF <br/>
                                    • Increased scaling and deployment velocity by migrating the application from a <b>Python Flask</b> monolith into a event-driven <b>Java Spring</b> microservice architecture while ensuring the security and integrity of beta customers’ data
                                </p>

                                <h5>Software Engineer Intern</h5>
                                <h6>July 2019 - December 2019 | San Diego</h6>
                                <p>
                                    • Saved engineering hours by automating CCPA / GDPR data privacy requests by designing and shipping a microservice from scratch using <b>Java</b> and <b>Kafka</b>
                                </p>
                                <hr/>

                                <h4>San Diego Supercomputer Center, UCSD</h4>
                                <h5>Software Developer / Researcher </h5>
                                <h6>January 2018 - December 2019, Part-time</h6>
                                <p>
                                    • Designed and built a platform for large-scale heterogeneous social media data research using <b>Java</b>, <b>Python</b>, <b>Bootstrap</b>, <b>PostgreSQL</b>, and <b>Neo4j</b>. The platform provides insights into social media data through graph-based exploration and data aggregation <br/>
                                    • Co-authored and published paper with IEEE <a href="https://ieeexplore.ieee.org/document/9041791" target="_blank">"Multi-model Investigative Exploration of Social Media Data with BOUTIQUE: A Case Study in Public Health."</a> Presented the paper at 2019 15th International Conference on eScience. <br/>
                                    • Implemented a pipeline that identifies user interaction network clusters and categorizes their behaviors by sampling and analyzing unstructured social media data using word2vec and topic model analysis <br/>
                                </p>
                                <hr/>
                                <h4>iOS Developer</h4>
                                <h6>April 2014 - January 2016</h6>
                                <p>
                                    • Developed and published iOS apps using <b>Objective-C</b> that generated 40,400 total downloads
                                </p>



                            </div> {/* end .main-col */}

                        </div>

                    </section> {/* About Section End*/}


                </React.Fragment>
            );
        }
    }
    render() {
        return this.state.renderComponent;

    }
}
