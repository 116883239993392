import React, { Component } from 'react';
import Image from './Image'
export default class About extends Component {
    render() {
        return (
            <React.Fragment>

                <section id="about">
                    <div className="row">
                        <div className='three columns'>
                                <h2><span style={{borderBottom: '2px solid', borderBottomColor:'#f22613'}}>Education</span></h2>
                                <img src={require('./UCSD_Seal-2.png')} />
                        </div>
                        <div className="nine columns main-col">
                            <h2>UC San Diego</h2>
                            <h3>B.S. Double Major: Math - Computer Science & Cognitive Science - Machine Learning </h3>
                            <h3><span style={{borderBottom: '2px solid', borderBottomColor:'#f22613'}}>Relevant Coursework</span></h3>
                            <ul>
                                <li>Object-Oriented Programming</li>
                                <li>Advanced Data Structures and Algorithms</li>
                                <li>Organization of System</li>
                                <li>Machine Learning and Deep Learning</li>
                                <li>Human Centered UX Design</li>
                            </ul>
                        </div> {/* end .main-col */}
                    </div>
                </section> {/* About Section End*/}


            </React.Fragment>
        );
    }
}
