import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = {
    card: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
};

function FullstackCard(props) {
    const { classes, titleX, description, img } = props;
    return (
        <Card className={classes.card} style={{ backgroundColor: '#3a4660' }}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={require('./UCSD_Seal-2.png')}
                    title={titleX}
                />
                <CardContent>
                    <Typography gutterBottom variant="h2" component="h2" color="error">
                        {titleX}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h4">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="large" color="primary">
                    Learn More
                </Button>
            </CardActions>
        </Card>
    );
}

FullstackCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullstackCard);
