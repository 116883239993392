import React, { Component } from 'react';
import FullstackCard from "./fullstackCard";
import Image from './Image'
import ATVEffect from './ATVEffect'

export default class About extends Component {
    render() {
        return (
            <React.Fragment>

                <section id="project2" style={{backgroundImage: 'linear-gradient(to bottom, #606c88, #3f4c6b)', height: 700}}>
                    <br/>
                    <h1 style={{ color: 'white', left: 100 }}> Projects </h1>
                    <div className='row'>
                        <div className='three columns'>
                            <ATVEffect title='Parallax Card' description='These cards you see in the project section is implemented in ReactJS. Click to see it on GitHub' bgImg = {require('./bg1.png')} link = {'https://github.com/junanguo/react-parallax-card'}/>
                        </div>

                        <div className='three columns'>
                            <ATVEffect title='AWESOME' description='A heterogeneous social data exploration platform. Click to learn more!' bgImg = {require('./bg4.png') } link={'https://drive.google.com/file/d/1sqUYzIt7Vt5QvAT-0g4ONtjkKvysei_r/view'}/>
                        </div>

                        <div className='three columns'>
                            <ATVEffect title='Graphing Calculator' description='Graphing Calculator for iOS (no longer available). Click to see screenshot' bgImg = {require('./bg2.jpg')} link={'https://drive.google.com/file/d/17ZVgT1Dejg0IshCU1_d5JD9RrZurFU_K/view?usp=sharing'}/>
                        </div>
                        <div className='three columns'>
                            <ATVEffect title='Openly.Club' description='Find personalized recommendations for open-source projects you can contribute to' bgImg = {require('./bg3.jpg')} link={'https://openly.club'}/>
                        </div>

                    </div>

                </section> {/* About Section End*/}


            </React.Fragment>
        );
    }
}
