import React, { Component } from 'react';
import Header from './header';
import About from './about';
import Experience from './Experience'
import Projects from './projects'
class App extends Component {
  render() {
    return (
        <div className="App">
          <Header />
          <About />
          <Experience/>
          <Projects/>
          <showcase/>
        </div>
    );
  }
}
export default App;
