import React, { Component } from 'react';

export default class Header extends Component {
    render() {
        return (
            <React.Fragment>
                <header id="home">
                    <nav id="nav-wrap">
                        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                        <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
                        <ul id="nav" className="nav">
                            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
                            <li><a className="smoothscroll" href="#about">Education</a></li>
                            <li><a className="smoothscroll" href="#resume">Experience</a></li>
                            <li><a className="smoothscroll" href="#project2">Projects</a></li>
                        </ul> {/* end #nav */}
                    </nav> {/* end #nav-wrap */}
                    <div className="row banner">
                        <div className="banner-text">

                            <h1>
                                I'm Junan Guo.

                            </h1>
                            <h3>
                                I'm a <span>Full-Stack Developer</span> based in <span>San Francisco</span>.
                            </h3>
                            <hr />
                            <ul className="social">
                                <li><a href="https://www.linkedin.com/in/junanguo" target="_blank"><i className="fa fa-linkedin" /></a></li>
                                <li><a href="mailto:ijunanguo@gmail.com"><i className="fa fa-envelope" /></a></li>
                                <li><a href="https://github.com/junanguo" target="_blank"><i className="fa fa-github" /></a></li>
                            </ul>

                        </div>
                    </div>
                    <p className="scrolldown">
                        <a className="smoothscroll" href="#about"><i className="icon-down-circle" /></a>
                    </p>
                </header> {/* Header End */}


            </React.Fragment>
        );
    }
}
